<template>
  <Layout>
    <div class="session-expired">
      <span class="message"> {{ $t('router.views.SessionExpired.firstPart') }}
        <a
          class="link"
          :href="loginUrl"
        > {{ $t('router.views.SessionExpired.reconnect') }}</a>
        {{ $t('router.views.SessionExpired.secondPart') }} <router-link
          v-t="'router.views.SessionExpired.goHome'"
          class="link"
          to="/"
        /> </span>
    </div>
  </Layout>
</template>

<script>
import Layout from '@/router/layouts/AuthenticatedLayout.vue'

export default {
  name: 'SessionExpired',
  components: {
    Layout
  },
  computed: {
    loginUrl () {
      return `${this.$store.state.auth.loginURL}`
    }
  }
}
</script>

<style scoped>
.session-expired{
  height: 100%;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-top: 5em;
  font-size: 1.75em;
}

.message {
  display: flex;
  justify-content: center;
  align-items: center;
}

.link{
  margin: 10px;
}
</style>
